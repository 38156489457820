exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-behandlingar-js": () => import("./../../../src/pages/behandlingar.js" /* webpackChunkName: "component---src-pages-behandlingar-js" */),
  "component---src-pages-boka-js": () => import("./../../../src/pages/boka.js" /* webpackChunkName: "component---src-pages-boka-js" */),
  "component---src-pages-hitta-hit-js": () => import("./../../../src/pages/hitta-hit.js" /* webpackChunkName: "component---src-pages-hitta-hit-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-oppettider-js": () => import("./../../../src/pages/oppettider.js" /* webpackChunkName: "component---src-pages-oppettider-js" */),
  "component---src-pages-personal-js": () => import("./../../../src/pages/personal.js" /* webpackChunkName: "component---src-pages-personal-js" */),
  "component---src-pages-priser-js": () => import("./../../../src/pages/priser.js" /* webpackChunkName: "component---src-pages-priser-js" */),
  "component---src-pages-tack-js": () => import("./../../../src/pages/tack.js" /* webpackChunkName: "component---src-pages-tack-js" */)
}

